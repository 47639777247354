import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Check,
  Save,
  ArrowAltCircleLeft,
  Trash,
  Cogs,
  AngleDoubleLeft,
} from '../utils/Fontawesome';

import { TablePagination } from '@material-ui/core';
import { sqlLIKE, mask_Numeros, removeAcento } from '../utils/Mascara';
const FilaGuiche = () => {
  let history = useHistory();

  const [page_top, setPage_top] = useState(null);
  const [page_left, setPage_left] = useState(null);
  const [page_right, setPage_right] = useState(null);
  const [page_bottom, setPage_bottom] = useState(null);
  const [page_med_id, setPage_med_id] = useState([]);
  const [statusPage_med_id, setStatusPage_med_id] = useState([]);
  const [page_id, setPage_id] = useState(0);

  const [pagina, setPagina] = useState([]);
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [paginaPag, setPaginaPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setPaginaPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setPaginaPag(usuariosPag);
  };
  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
  //  getMedico();
    getPagina();
  }, []);

  const catchResponse = (error) => {
    if (error.response) {
      // O erro é uma resposta HTTP com um status diferente de 2xx
    //  console.error( error.response.status);
    if(error.response.status === 401){
      if (document.getElementById('btn_limpar')) {
        document.getElementById('btn_limpar').click();
      }
      handleInfo('Sessão expirada.');
      history.push('/login'); 
    }
    handleInfo( error.response.data.error.description);

    } else if (error.request) {
      // O erro ocorreu durante a requisição, mas não houve resposta do servidor
      handleInfo( error.request);
    } else {
      // Outros erros
      handleInfo(error.message);
     // handleError('Ocorreu um erro inesperado na execução desta operação');
    }  
  };

  const getPagina = () => {
    api
      .get('api/filaespera?senha_agencia_id=' + sessionStorage.getItem('usuario_agencia_id')+'&senha_usuario_id=99999', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setPagina(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setPaginaPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error);
        }
      });
  };

  const getMedico = () => {
    let parametro = '?secretaria=' + sessionStorage.getItem('secretaria_id');
    const medicoArray = [];

    if (sessionStorage.getItem('cargo') === 'M') {
      setPage_med_id({
        label: sessionStorage.getItem('med_nome'),
        value: sessionStorage.getItem('med_id'),
      });
      medicoArray.push({
        label: sessionStorage.getItem('med_nome'),
        value: sessionStorage.getItem('med_id'),
      });

      setStatusPage_med_id(medicoArray);
    } else {
      api
        .get('api/medicos' + parametro, {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
            tcdocpw: sessionStorage.getItem('senha'),
            tcdocuserlogin:
              sessionStorage.getItem('cargo') === 'M'
                ? sessionStorage.getItem('med_email')
                : sessionStorage.getItem('secretaria_email'),
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            medicoArray.push({
              label: response.data[i].med_nome,
              value: response.data[i].med_id,
            });
          }
          setStatusPage_med_id(medicoArray);
        })
        .catch((error) => {
          if (error.response) {
            catchResponse(error);
          }
        });
    }
  };

  const editar = (pagina) => {
    document.getElementById('page_top').focus();

    setPage_right(pagina.page_right);
    setPage_left(pagina.page_left);
    setPage_top(pagina.page_top);
    setPage_bottom(pagina.page_bottom);
    setPage_med_id({
      value: pagina.page_med_id,
      label: pagina.med_nome,
    });
    setPage_id(pagina.page_id);
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  
  const confirmar = (senha) => {

   let data = {
      senha_id: senha.senha_id,
      senha_usuario_id: parseInt(sessionStorage.getItem('usuario_id')),    
    };

    api({
      method: 'POST',
      url: 'api/gerarsenha/',
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getPagina();
      })
      .catch((error) => {
   
       
        catchResponse(error);

      });
  };
  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method, url;
    if (metodo) {
      method = 'delete';
    } else {
      if (!page_id) {
        method = 'post';
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        page_id: page_id,
        page_top: page_top,
        page_bottom: page_bottom,
        page_left: page_left,
        page_right: page_right,
        page_med_id: page_med_id.value,
      };
      url = 'api/paginas/' + page_id;
    } else if (method === 'post') {
      data = {
        page_top: page_top,
        page_bottom: page_bottom,
        page_left: page_left,
        page_right: page_right,
        page_med_id: page_med_id.value,
      };
      url = 'api/paginas';
    } else if (method === 'delete') {
      // url = url + "/" + idModal + "/" + status;
      url = 'api/paginas/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getPagina();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error);
        console.log(error);
      });
  };

  const handleChangeMedico = (medico) => {
    setPage_med_id(medico);
  };
  const formatarData = (data) => {
    if(data){
      let dataHoraString = data;

// Dividir a string em data e hora
let [dataPart, horaPart] = dataHoraString.split(' ');

// Dividir a data em ano, mês e dia
let [ano, mes, dia] = dataPart.split('-');

// Dividir a hora em horas, minutos e segundos
let [horas, minutos, segundos] = horaPart.split(':');

// Remover os milissegundos
let segundosSemMilissegundos = segundos.split('.')[0];

// Formate a data e hora no padrão brasileiro, excluindo os milissegundos
let dataHoraFormatada = `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundosSemMilissegundos}`;


return dataHoraFormatada;
    }
   // Data e hora no formato string

  };

 

  const limpar = () => {
    setPage_top(null);
    setPage_left(null);
    setPage_right(null);
    setPage_bottom(null);
    setPage_med_id([]);
    setPage_id(0);

    setNomeModal('');
    setIdModal('');
    if (sessionStorage.getItem('cargo') === 'M') {
      setPage_med_id({
        label: sessionStorage.getItem('med_nome'),
        value: sessionStorage.getItem('med_id'),
      });

      setStatusPage_med_id([
        {
          label: sessionStorage.getItem('med_nome'),
          value: sessionStorage.getItem('med_id'),
        },
      ]);
    }
  };
  const buscar = async () => {
    var filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = pagina;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.med_nome, '%' + busca + '%');
          //  return ilikeToRegExp('%' + busca + '%').test(obj.med_nome);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = pagina;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setPaginaPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };
  return (
    <>
    
      <div style={{display:"flex",height:"-webkit-fill-available"}} >
     
      <div class="last-ticket">
      {paginaPag.length > 0
                    ? paginaPag.map(function (paginas, i) {
                       if(i < 1){

                      
                        return (
                          <>             

       <div class="last-ticket-center" >
        
       <p id="last-ticket-number">{paginas.caixa_sigla + ''+paginas.senha_senha}</p>
            <p>{'Guiche '+paginas.senha_usuario_id }</p>
      
            </div>



            
            </>
                        );
                      }
                      })
                    : ''}



        </div>
        <div class="ticket-list">
        
            <h2><b>Últimas chamadas</b></h2>
            <ul id="ticket-list">
          
                {paginaPag.length > 0
                    ? paginaPag.map(function (paginas, i) {
                       if(i < 5){

                      
                        return (
                          <>                                  
                         <li>{paginas.caixa_sigla + ''+paginas.senha_senha}</li>
                    
                     
                          </>
                        );
                      }
                      })
                    : ''}
            </ul>
        </div>
        

       
         
    

           {/* <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>	
             
                    <th scope="col">Senha</th>
                    <th scope="col">Caixa</th>
                    <th scope="col">Data</th>                    
                    <th scope="col">Guiche</th>          
                    <th scope="col">Data atendimento</th>
                
                  </tr>
                </thead>
                <tbody>
                  {paginaPag.length > 0
                    ? paginaPag.map(function (paginas, i) {

                        return (
                          <>
                            <tr key={paginas.senha_id}>
                        
                              <th>{paginas.caixa_sigla + ' '+paginas.senha_senha}</th>
                              <th>{paginas.caixa_nome}</th>
                              <th>{formatarData(paginas.senha_data)}</th>
                              <th>{paginas.senha_usuario_id }</th>
                      
                              <th>{formatarData(paginas.senha_dataatendimento)}</th>
                      
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>        
                    */}
      </div>
        {/*  <div>
    
<h1>grid-area: 1 / 2 / 4 / 3;</h1>
<section class="grid grid-area-1">
	<div class="item item-1">1</div>
	<div class="item item-2">2</div>
	<div class="item item-3">3</div>
	<div class="item item-4">4</div>
	<div class="item item-5">5</div>
	<div class="item item-6">6</div>
</section>
</div>

                  */}


      
    </>
  );
};
export default FilaGuiche;
