import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserEdit,
  faShoppingCart,
  faUser,
  faBars,
  faEye,
  faSearch,
  faSave,
  faSignOutAlt,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faShareAlt,
  faHandPointLeft,
  faTrash,
  faEnvelope,
  faEdit,
  faTimes,
  faExchangeAlt,
  faCarSide,
  faLock,
  faAngleDoubleLeft,
  faPlusCircle,
  faPlus,
  faFileExcel,
  faFileExport,
  faClock,
  faUserMd,
  faUserClock,
  faKey,
  faCalendarAlt,
  faBriefcaseMedical,
  faHeadSideMask,
  faAddressCard,
  faCity,
  faUsers,
  faAlignJustify,
  faClipboard,
  faCheck,
  faPhoneAlt,
  faFileMedicalAlt,
  faBookMedical,
  faCogs,
  faImage,
  faPrint,
  faCog,
  faFile,
  faClipboardList,
  faUserNurse,
  faHistory,
  faExclamationTriangle,
  faMinus,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
  faWhatsappSquare,
  faTwitter,
  faWhatsapp,
  faAndroid,
} from '@fortawesome/free-brands-svg-icons';

//free-solid-svg-icons -----------------------------------------------------------------------

export const ExclamationTriangle = (
  <FontAwesomeIcon icon={faExclamationTriangle} />
);

export const AngleDoubleRight = <FontAwesomeIcon icon={faAngleDoubleRight} />;
export const Minus = <FontAwesomeIcon icon={faMinus} />;
export const History = <FontAwesomeIcon icon={faHistory} />;
export const UserNurse = <FontAwesomeIcon icon={faUserNurse} />;
export const Print = <FontAwesomeIcon icon={faPrint} />;
export const ImageUpload = <FontAwesomeIcon icon={faImage} />;
export const Cogs = <FontAwesomeIcon icon={faCogs} />;
export const Cog = <FontAwesomeIcon icon={faCog} />;
export const BookMedical = <FontAwesomeIcon icon={faBookMedical} />;
export const FileMedicalAlt = <FontAwesomeIcon icon={faFileMedicalAlt} />;
export const PhoneAlt = <FontAwesomeIcon icon={faPhoneAlt} />;
export const Check = <FontAwesomeIcon icon={faCheck} />;
export const Clipboard = <FontAwesomeIcon icon={faClipboard} />;
export const AlignJustify = <FontAwesomeIcon icon={faAlignJustify} />;
export const Users = <FontAwesomeIcon icon={faUsers} />;
export const City = <FontAwesomeIcon icon={faCity} />;
export const AddressCard = <FontAwesomeIcon icon={faAddressCard} />;
export const HeadSideMask = <FontAwesomeIcon icon={faHeadSideMask} />;
export const BriefcaseMedical = <FontAwesomeIcon icon={faBriefcaseMedical} />;
export const CalendarAlt = <FontAwesomeIcon icon={faCalendarAlt} />;
export const Key = <FontAwesomeIcon icon={faKey} />;
export const UserClock = <FontAwesomeIcon icon={faUserClock} />;
export const UserMd = <FontAwesomeIcon icon={faUserMd} />;
export const Clock = <FontAwesomeIcon icon={faClock} />;
export const FileExcel = <FontAwesomeIcon icon={faFileExcel} />;
export const FileExport = <FontAwesomeIcon icon={faFileExport} />;
export const PlusCircle = <FontAwesomeIcon icon={faPlusCircle} />;
export const Plus = <FontAwesomeIcon icon={faPlus} />;
export const AngleDoubleLeft = <FontAwesomeIcon icon={faAngleDoubleLeft} />;
export const User = <FontAwesomeIcon icon={faUser} />;
export const Lock = <FontAwesomeIcon icon={faLock} />;
export const Envelope = <FontAwesomeIcon icon={faEnvelope} />;
export const CarSide = <FontAwesomeIcon icon={faCarSide} />;
export const ExchangeAlt = <FontAwesomeIcon icon={faExchangeAlt} />;
export const Times = <FontAwesomeIcon icon={faTimes} />;
export const Edit = <FontAwesomeIcon icon={faEdit} />;
export const Trash = <FontAwesomeIcon icon={faTrash} />;
export const HandPointLeft = <FontAwesomeIcon icon={faHandPointLeft} />;
export const ShareAlt = <FontAwesomeIcon icon={faShareAlt} />;
export const ShoppingCart = <FontAwesomeIcon icon={faShoppingCart} />;
export const UserEdit = <FontAwesomeIcon icon={faUserEdit} />;
export const SignOutAlt = <FontAwesomeIcon icon={faSignOutAlt} />;
export const Save = <FontAwesomeIcon icon={faSave} />;
export const Search = <FontAwesomeIcon icon={faSearch} />;
export const Bars = <FontAwesomeIcon icon={faBars} />;
export const Eye = <FontAwesomeIcon icon={faEye} />;
export const Android = <FontAwesomeIcon icon={faAndroid} />;
export const File = <FontAwesomeIcon icon={faFile} />;
export const ClipboardList = <FontAwesomeIcon icon={faClipboardList} />;

export const ArrowAltCircleRight = (
  <FontAwesomeIcon icon={faArrowAltCircleRight} />
);
export const ArrowAltCircleLeft = (
  <FontAwesomeIcon icon={faArrowAltCircleLeft} />
);

// fim dos free-solid-svg-icons ----------------------------------------------------------------

//free-brands-svg-icons -----------------------------------------------------------------------
export const Whatsapp = <FontAwesomeIcon icon={faWhatsapp} />;
export const FacebookSquare = <FontAwesomeIcon icon={faFacebookSquare} />;
export const Instagram = <FontAwesomeIcon icon={faInstagram} />;
export const Youtube = <FontAwesomeIcon icon={faYoutube} />;
export const WhatsappSquare = <FontAwesomeIcon icon={faWhatsappSquare} />;
export const Twitter = <FontAwesomeIcon icon={faTwitter} />;

// fim dos free-brands-svg-icons ----------------------------------------------------------------
